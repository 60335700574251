<template>
  <div>
    <div class="input-search__wrapper" :class="{ hide: isHideSearch }">
      <input
        v-model="searchText"
        id="search"
        size="sm"
        @input="onSearch"
        type="text"
        class="form-control input-search"
        @click="onOpenSearch"
      >
      <a v-if="!isHideSearch" href="#" @click.prevent="onResetSearch">
        <span class="icon-cancel"></span>
      </a>
    </div>
  </div>
</template>
<script>
import FriendService from '@/services/FriendService';

export default {
  name: 'SearchMenu',
  components: {},
  data() {
    return {
      groupID: this.$route.params.id,
      searchText: '',
      pagination: null,
      isHideSearch: true,
    };
  },
  computed: {},
  methods: {
    async onSearch() {
      if (this.searchText.length < 3 && this.searchText.length > 0) {
        return;
      }

      let res = null;
      try {
        res = await FriendService.get({
          filter: 'all',
          name: this.searchText,
          page: 1,
          'per-page': 15,
        });

        this.pagination = {
          pageCurrent: res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 15,
        };

        this.$emit('onsearch', res.data, this.pagination, this.searchText);
      } catch (e) {
        console.log({ textArg: e.response.data.message, variantArg: 'danger' });
        this.$emit('onsearch', null, null, this.searchText);
      }
    },
    async onResetSearch() {
      let res = null;

      this.isHideSearch = true;

      if (!this.searchText) {
        this.searchText = '';
        return;
      }

      this.searchText = '';

      try {
        res = await FriendService.get({
          page: 1,
          'per-page': 15,
        });

        this.pagination = {
          pageCurrent: res.headers['x-pagination-current-page'],
          pageCount: +res.headers['x-pagination-page-count'] || 1,
          perPage: res.headers['x-pagination-per-page'],
          total: +res.headers['x-pagination-total-count'] || 15,
        };

        this.$emit('onsearch', res.data, this.pagination, this.searchText);
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        this.$emit('onsearch', null, null, this.searchText);
      }
    },
    onOpenSearch() {
      if (!this.isHideSearch) return;
      this.isHideSearch = !this.isHideSearch;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .group_menu_search {
  width: 220px;

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.search_container {
  ::v-deep [role="group"] {
    position: relative;
  }

  &__reset {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #1d9dd9;
    cursor: pointer;
  }
}

.hide {
  input {
    width: 60px;
    padding: 0;
    cursor: pointer;
  }
}
</style>
