<template>
  <b-dropdown
    id="dropdown-form"
    text="Add a friend"
    ref="dropdown"
    class="invite"
    menu-class="dropdown-round-box">
    <ValidationObserver slim v-slot="{ handleSubmit }">
      <b-dropdown-form @submit.prevent="handleSubmit(onInviteByID)">
        <div class="form-row">
          <multiselect
            v-model="selectedUser"
            id="ajax"
            :options="usersOptions"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :show-no-results="false"
            placeholder="Search for people..."
            label="text"
            track-by="id"
            @search-change="findUser"
          >
          </multiselect>
        </div>
        <div class="btns-group--right">
          <button class="btn btn-primary btn_invite" :disabled="!selectedUser">Add a friend</button>
        </div>
      </b-dropdown-form>
    </ValidationObserver>
  </b-dropdown>
</template>
<script>
import Multiselect from 'vue-multiselect';
import {
  BDropdown,
  BDropdownForm,
} from 'bootstrap-vue';

import FriendService from '@/services/FriendService';

export default {
  name: 'InviteModal',
  components: {
    Multiselect,
    BDropdown,
    BDropdownForm,
  },
  data() {
    return {
      selectedUser: null,
      usersOptions: [],
    };
  },
  computed: {},
  methods: {
    async findUser(query) {
      if (query.length < 3) return;

      const params = {
        name: query,
      };

      try {
        const res = await FriendService.getFriendsByParams(params);
        this.usersOptions = res.data;
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
      }
    },
    async onInviteByID() {
      this.$emit('invite', this.selectedUser.id);
      this.selectedUser = null;
      this.$refs.dropdown.hide();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .invite button.btn_invite {
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 100px;
  }

  .invite button.btn_invite[disabled="disabled"] {
    cursor: not-allowed;
    background-color: #f2f4f6;
  }

  .invite button.btn_invite[disabled="disabled"]:hover {
    background-color: #f2f4f6;
    color: #000;
  }

  ::v-deep .dropdown-menu {
    width: 359px;
  }

  @media ( max-width: 360px ) {
    ::v-deep .dropdown-menu {
      width: 320px;
    }
  }

  ::v-deep .multiselect__tags {
    padding: 12px 40px 2px 8px;
    border-radius: 15px;
    height: 52px;
  }

  ::v-deep .multiselect--active:not(.multiselect--above) .multiselect__tags {
      height: 52px;
      padding-top: 17px;
  }

  ::v-deep .multiselect__single {
    padding-top: 5px;
  }

  ::v-deep .multiselect__select:before {
      top: 85%;
  }

  ::v-deep .multiselect--active:not(.multiselect--above) .multiselect__select:before {
      top: 45%;
  }

  ::v-deep .multiselect__placeholder {
      padding-left: 12px;
      color: #787878;
  }
</style>
