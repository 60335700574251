<template>
  <div class="friends__item">
    <span v-if="unreadFriend"
      class="message_badge"
      :class="getClassBadgeLength(unreadFriend.cnt)"
    >{{unreadFriend.cnt}}</span>
    <div class="friends__avatar-box">
      <div
        v-if="friend.avatar"
        class="friends__avatar-img"
        :style="'background-image: url(' + friend.avatar + ')'">
      </div>
      <div
        v-else
        class="friends__avatar-img"
        :style="'background-image: url(' +
          require('@/assets/images/avatar.svg') +
          ')'"
      >
      </div>
    </div>
    <div class="friends__email">
      <router-link
        v-if="friend.status === 'approved'"
        :to="{ name: 'MessagesPage', params: { id: friend.id } }"
      >
        <span class="icon-friend-email"></span>
      </router-link>
    </div>
    <div class="friends__name">{{friend.name}}</div>
    <a v-if="friend.status === 'approved'"
      class="friends__btn"
      href="#"
      @click.prevent="onUnfriend">Unfriend
    </a>
    <a v-if="friend.status === 'received'"
      class="friends__btn accept__btn"
      href="#"
      @click.prevent="onAccept">Accept
    </a>
    <a v-if="friend.status === 'received'"
      class="friends__btn"
      href="#"
      @click.prevent="onDeny">Deny
    </a>
    <span v-if="friend.status !== 'received' && friend.status !== 'approved'"
      class="friends__btn status__btn"
    >{{friend.status}}
    </span>
  </div>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'CardFriend',
  components: {},
  mixins: [badge],
  props: {
    friend: {
      type: Object,
      required: true,
    },
    unreadFriend: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onUnfriend() {
      this.$emit('unfriend', this.friend.id, 'unfriend', 'revoked');
    },
    onAccept() {
      this.$emit('accept', this.friend.id, 'accept', 'approved');
    },
    onDeny() {
      this.$emit('deny', this.friend.id, 'deny', 'deny');
    },
  },
};
</script>
<style lang="scss" scoped>
.accept__btn {
  bottom: 90px;
}

.status__btn:hover {
  background-color: #72CBED;
  color: #000000;
  cursor: default;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    right: -20px;
  }
}
</style>
