<template>
  <div class="project-header">
    <nav class="project-header__actions">
      <ul class="project-header__menu">
        <li class="project-header__menu-item">
          <InviteModal
            v-on:invite="onInvite"
            v-on:inviteByEmail="onInviteByEmail"
          />
        </li>
        <li class="project-header__menu-item">
          <SearchMenu @onsearch="onSearch"/>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import InviteModal from '@/views/profile/Friends/Components/InviteModal.vue';
import SearchMenu from '@/views/profile/Friends/Components/SearchMenu.vue';

export default {
  name: 'FriendsHeader',
  mixins: [],
  components: {
    InviteModal,
    SearchMenu,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    onInvite(userID) {
      this.$emit('invite', userID);
    },
    onInviteByEmail(data) {
      this.$emit('onInviteByEmail', data);
    },
    onSearch(members, pagination, searchText) {
      this.$emit('search', members, pagination, searchText);
    },
  },
};
</script>
<style lang="scss" scoped>
  .user-cabinet__inner {
    padding: 0;
  }

  .project-header {
    justify-content: flex-end;
  }

  .invite {
    ::v-deep button {
      padding: 13px 30px;
      border-radius: 10px;
      border: none;
      font-size: 16px;
      line-height: 26px;
      outline: none;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }
  }
</style>
