<template>
<div>
  <ProjectHeader
    v-on:invite="onInvite"
    v-on:onInviteByEmail="onInviteByEmail"
    v-on:search="onSearch"
  />
  <div class="user-cabinet__inner">
    <transition name="slide-fade">
      <div class="container">
        <div v-if="text" class="container_alert">
          <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        </div>
      </div>
    </transition>
    <div class="friends__area">
      <div v-if="friends.length" class="friends__list">
        <CardFriend v-for="friend in friends"
          :key="friend.id + friend.name"
          :friend="friend"
          :unreadFriend="unreadFriends.find((fr) => fr.id === friend.id)"
          @unfriend="onShowModal"
          @accept="onShowModal"
          @deny="onShowModal"
        />
      </div>
      <p v-else-if="friendsLoaded">Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
        sed diam nonummy nibh
        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim
        veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
        commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
        molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
        et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
        dolore te feugait nulla facilisi.
      </p>
      <Pagination v-if="pagination.pageCount > 1"
        :pagination="pagination"
        v-on:changepage="getFriends"
      />
  </div>
  <Modal
    @close="hideModal(modal.accept)"
    :id="modal.accept"
    title="Accept"
    buttonTextOk="Accept"
    buttonVariantOk="danger"
    :showButtonCancel="true"
    @cancel="hideModal(modal.accept)"
    @ok="onActionFriend"
  >
    <p>Are you sure?</p>
  </Modal>
  <Modal
    @close="hideModal(modal.deny)"
    :id="modal.deny"
    title="Deny"
    buttonTextOk="Deny"
    buttonVariantOk="danger"
    :showButtonCancel="true"
    @cancel="hideModal(modal.deny)"
    @ok="onActionFriend"
  >
    <p>Are you sure?</p>
  </Modal>
  <Modal
    @close="hideModal(modal.unfriend)"
    :id="modal.unfriend"
    title="Unfriend"
    buttonTextOk="Unfriend"
    buttonVariantOk="danger"
    :showButtonCancel="true"
    @cancel="hideModal(modal.unfriend)"
    @ok="onActionFriend"
  >
    <p>Are you sure?</p>
  </Modal>
</div>
</div>
</template>
<script>
import UserService from '@/services/UserService';
import FriendService from '@/services/FriendService';

import random from '@/mixins/random';
import alert from '@/mixins/alert';
import modal from '@/mixins/modal';
import pagination from '@/mixins/pagination';
import content from '@/mixins/content';

import ProjectHeader from '@/views/profile/Friends/Components/ProjectHeader.vue';
import CardFriend from '@/views/profile/Friends/Components/CardFriend.vue';
import Pagination from '@/views/profile/MyGroups/Pagination.vue';

import Alert from '@/components/Alert.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Friends',
  mixins: [random, alert, modal, pagination, content],
  components: {
    ProjectHeader,
    CardFriend,
    Alert,
    Modal,
    Pagination,
  },
  props: {},
  data() {
    return {
      friends: [],
      friendsLoaded: false,
      friendID: 0,
      modal: {
        unfriend: 'modalUnfriend',
        accept: 'modalAccept',
        deny: 'modalDeny',
      },
      searchMembers: null,
      searchPagination: null,
      searchText: undefined,
      type: '',
      modalName: '',
    };
  },
  computed: {
    unreadFriends() {
      return this.$store.getters.getUnreadMembers;
    },
  },
  async created() {
    await this.getFriends();
    this.$eventBus.$on('addedProfileFriend', this.addFriend);
    this.$eventBus.$emit('setFriendsCount', 0, 'friends');
  },
  methods: {
    async getFriends() {
      let res = null;
      const data = {
        filter: 'all',
        page: this.$route.query.page || 1,
        'per-page': 15,
      };

      try {
        res = await FriendService.get(data);
        this.friends = res.data;
        this.friendsLoaded = true;
        this.setPagination(res.headers);
      } catch (e) {
        console.log({ textArg: e, variantArg: 'danger' });
        if (e.response.status === 404) {
          this.$router.push({ name: 404 });
        }

        this.friends = [];
      }
    },
    onShowModal(friendID, name, type) {
      this.friendID = friendID;
      this.type = type;
      this.modalName = name;
      this.showModal(this.modal[this.modalName]);
    },
    async onActionFriend() {
      let res = null;
      try {
        res = await FriendService.actionUser({
          action: this.type,
          user_id: this.friendID,
        });

        this.changeStatus();
        this.hideModal(this.modal[this.modalName]);
        this.showAndHideAlertSuccess(res.data.message);
      } catch (e) {
        console.log(e);
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }

      // document.getElementById(this.slug).scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    changeStatus() {
      if (this.type === 'revoked' || this.type === 'deny') {
        this.friends = this.friends.filter((friend) => friend.id !== this.friendID);
      } else if (this.type === 'approved') {
        this.friends.find((friend) => friend.id === this.friendID).status = 'approved';
      }
    },
    async onInvite(userID) {
      let res = null;
      const data = {
        user_id: userID,
        action: 'sent',
      };

      try {
        res = await FriendService.invite(data);
        this.setAlert({
          textArg: res.data.message,
          variantArg: (res.data.status && res.data.status === 500) ? 'danger' : 'success',
        });
        setTimeout(() => {
          this.removeAlert();
        }, 5000);
      } catch (e) {
        this.setAlert({ textArg: e.response.data.message, variantArg: 'danger' });
      }
    },
    async onInviteByEmail(data) {
      let res = null;
      try {
        res = await UserService.invite(data);
        this.setAlert({
          textArg: res.data.message,
          variantArg: (res.data.status && res.data.status === 500) ? 'danger' : 'success',
        });
        setTimeout(() => {
          this.removeAlert();
        }, 5000);
      } catch (e) {
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
    onSearch(members, paginate, searchText) {
      this.friends = members;
      this.searchPagination = paginate;
      this.searchText = searchText;
    },
    addFriend(friend) {
      console.log(friend);
      this.friends.unshift(friend);

      if (this.friends.length > 15) {
        this.friends.pop();
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('addedProfileFriend', this.addFriend);

    this.$store.commit('SET_UNREAD_MEMBERS', []);
  },
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
